export enum Entities {
  Users = 'user',
  My_Products = 'my_products',
  Students = 'student',
  Roles = 'role',
  Choice = 'choice',
  Tenants = 'tenant',
  BaseData = 'base-data',
  BaseDataKey = 'base-data-key',
  Settings = 'setting',
  Translations = 'translation',
  TranslationsWithS = 'translations',
  Municipalities = 'municipality',
  Instances = 'instance',
  Schools = 'school',
  Regions = 'region',
  Phases = 'signup-phase',
  Communications = 'communication',
  Clusters = 'cluster',
  Monitoring = 'monitoring',
  OtherManagement = 'other-management',
  MboInstitutions = 'mbo-institution',
  DaycareOrganisation = 'daycare-organisation',
  MboCheckService = 'mbo-check-service',
  VoInstitution = 'vo-institution',
  MboSchool = 'mbo-school',
  VoSchool = 'vo-school',
  VoGroups = 'vo-school-group',
  RecipientGroup = 'recipient-group',
  Template = 'template',
  Module = 'module',
  SignupStatus = 'signup-status',
  Signups_decreased_in_status = 'status-decreased',
  TodoStudents = 'todo-student',
  MboCheck = 'mbo-check',
  Mentors = 'mentor',
  ChildMentor = 'child-mentor',
  StatusAlias = 'signup-status-alias',
  MboWebservicesConnection = 'mbo-webservices-connection',
  MboWebservicesLog = 'mbo-webservices-log',
  DiplomaStudent = 'diploma-student',
  StandardTemplate = 'standard-template',
  Exports = 'exports',
  Progress = 'vo-school-progress',
  MboSchoolProgress = 'mbo-school-progress',
  Trainings = 'trainings',
  Rio = 'rio-connection',
  CreboImport = 'crebo-import',
  ActiveConnection = 'active-connection',
  RiskStudent = 'risk-student',
  CreboList = 'crebo-list',
  Private = 'private',
  Pronexus = 'pronexus',
  PronexusService = 'pronexus-service',
  PronexusOutgoingLog = 'pronexus-outgoing',
  PronexusIncomingLog = 'pronexus-incoming',
  ConnectionsManagement = 'connections-management',
  Transfer = 'transfer',
  TransferCoach = 'transfer-coach',
  MboProgress = 'mbo-progress',
  MboCheckFetchService = 'mbo-check-fetch-service',
  SharedFile = 'shared-file',
  List = 'list',
  Info = 'info',
  OtherSettings = 'other-settings',
  Sales = 'sales',
  Values = 'values',
  Answer = 'answer',
  Cron = 'cron',
  Uploads = 'uploads',
  Signups = 'signups',
  DataSync = 'data-sync',
  Overview = 'overview',
  Sector = 'sector',
  ImportStudent = 'private/list/student',
  FetchService = 'fetch-service',
  CreboList1 = '_portal/admin/crebo?tab=todo',
  ContentNotificationsSuper = '_portal/admin/text',
  ContentNotificationsAdmin = '_portal/contact/text',
  ContentNotification = 'content-notification',
  NewsMessages = 'news-messages',
  Messages = 'messages',
  News = 'news',
  DiscussionRound = 'discussion-round',
  StudentsToDiscuss = 'students-discuss',
  DashboardRMC = 'dashboard-rmc',
  DashboardUS = 'dashboard-us',
  UploadedFiles = 'uploaded-files',
  ServicePoint = 'service-point',
  DashboardReports = 'reports',
  DashboardReport = 'report',
  DashboardSubMenu = 'dashboard-sub-menu',
  DashboardClient = 'dashboard-client',
  DashboardRmcVsvFactsheet = 'vsv-factsheet',
  DashboardRmcVsvProgress = 'vsv-progress',
  DashboardRmcVsvEducation = 'vsv-education',
  DashboardRmcVsvWork = 'vsv-work',
  DashboardRmcVsvOther = 'vsv-other',
  DashboardRmcVsvTargets = 'vsv-targets',
  DashboardRmcVsvRegionalWorklists = 'vsv-regional-worklists',
  DashboardRmcExportVsvRegionalWorklists = 'export-vsv-regional-worklists',
  DashboardRmcJikpOverview = 'jikp-overview',
  DashboardRmcJikpEducation = 'jikp-education',
  DashboardRmcJikpWork = 'jikp-work',
  DashboardRmcJikpDaycare = 'jikp-daycare',
  DashboardRmcJikpOther = 'jikp-other',
  DashboardRmcJikpRegionalWorklists = 'jikp-regional-worklists',
  DashboardRmcExportJikpRegionalWorklists = 'export-jikp-regional-worklists',
  DashboardRmcExportVsv = 'export-vsv-file',
  DashboardRmcNoCDelivery = 'no-c-delivery',
  DashboardUSStudentLevel = 'student-level',
  DashboardUSTotalDataOverview = 'total-data-overview',
  DashboardUSSchoolLevel = 'school-level',
  DashboardUSSchoolLevelRegional = 'school-level-regional',
  DashboardUSMBO = 'mbo',
  DashboardUSMBORegional = 'mbo-regional',
  DashboardUSGraduated = 'graduated',
  DashboardUSGraduatedRegional = 'graduated-regional',
  DashboardUSVoReport = 'vo-report',
  DashboardUSMboReport = 'mbo-report',
  DashboardUSMboDownloadReport = 'mbo-download-report',
  Processed = 'processed',
  Export = 'export',
  Vo = 'vo',
  Region = 'region',
  RmcRegion = 'rmc-region',
  MboCheckMonitor = 'mbo-check-monitor',
  NationalProgress = 'national-progress',
  NationalProgressOverstrapVo = 'national-progress-overstap-vo',
  System = 'system',
  SystemPreference = 'system-preference',
  Mandrill = 'mandrill',
  StudentLevel = 'student-level',
  Report = 'report',
  ClassOverview = 'class-overview',
  StudentOverview = 'student-overview',

  ClusterOverview = 'cluster-overview',
  // Legacy

  ddd = 'ddd',
  dossiers = 'dossiers',
  dossier = 'dossier',
  mboDossiers = 'mbo-dossiers',
  voDossiers = 'vo-dossiers',
  dossierTodo = 'dossier/todo',
  specialTransferManage = 'specialtransfer/manage',
  specialTransferTodo = 'specialtransfer/todo',
  specialTransfer = 'specialtransfer',
  specialTransfers = 'specialtransfers',
  training = 'training',
  setting = 'settings',
  pipeline = 'pipeline',
  choiceStudentYear = 'choice-student-year',
  reports = 'reports',
  report = 'report',
  choiceReport = 'choice_report',
  dddReport = 'ddd_vombo_report',
  transferReport = 'transfer_report',
  choice = 'choice',
  user = 'user',
  setup = 'setup',
  voAllDossiers = 'dossier', // #/tab/alle-dossiers',
  voArchivedDossiers = 'dossier', // #/tab/gearchiveerde-dossiers',
  voCompletedFiles = 'dossier', // #/tab/afgeronde-dossiers',
  voApprove = 'dossier', // #/tab/dossiers-in-akkoordverklaring',
  voPartB = 'dossier', // #/tab/dossiers-in-deel-b',
  voPartA = 'dossier', // #/tab/dossiers-in-deel-a',
  opps = 'opps',
  opp = 'opp',
  oppTodo = 'opp/todo',
  myStudents = 'dossier/student/grade/4',
  voReports = 'vo-reports',
  voTransferReport = 'transfer_report/vo',
  voDddReport = 'ddd_vombo_report/vo',
  voReport = 'report/vo',
  institutions = 'mbo-institutions',
  participatingChoices = 'participatingChoices',
  crebolist = 'crebolist',
  formSettings = 'form-settings',
  forms = 'forms',
  formType = 'form_type',
  part = 'part',
  step = 'step',
  block = 'block',
  question = 'question',
  reply = 'reply',
  answerTypes = 'answer_type',
  subMenuSettings = 'setting',
  general = 'general',
  notificationCenter = 'notification-center',
  emailDomain = 'email_domain',
  vo = 'vo',
  settingMboSchool = 'mbo_school',
  settingRegion = 'region',
  course = 'course',
  transfers = 'transfers',
  transferSpecial = 'transferspecialtransfer',
  transferCare = 'transfercarepart',
  transferOpp = 'transferopp',
  connections = 'connections',
  connectionOverview = 'link',
  connectionType = 'linkblueprints',
  wsdlSetup = 'wsdl',
  eld2 = 'eld2',
  connectionSettings = 'linksetting',
  fields = 'linkfield',
  extraData = 'extradata',
  choiceLink = 'eld2choicelink',
  connectionMboCheck = 'link/mbocheck',
  tools = 'tools',
  cronOverview = 'cron/overview',
  cronLog = 'tools/cron/log',
  toolsCache = 'tools/cache',
  toolsStudentSet = 'tools/studentset',
  laravelLog = 'tools/logs/laravellog',
  errorLog = 'tools/logs/errorlog',
  mboSettings = 'mbo-settings',
  location = 'location',
  lobDossier = 'lobdossier',
  lobManagement = 'lob-management',
  management = 'management',
  format = 'format',
  competences = 'competences',
  managementRegion = 'instance/region',
  managementMbo = 'instance/choice',
  managementVo = 'instance/vo',
  assignment = 'assignment',
  assignmentRegionAll = 'assignment/region/all',
  assignmentGeneralArchived = 'assignment/general/archived',
  lobAssignment = 'lob-assignment',
  assignmentAll = 'assignment/all',
  assignmentReceived = 'assignment/received',
  assignmentShared = 'assignment/shared',
  assignmentArchived = 'assignment/archived',
  assignmentOverview = 'assignment/general/all',
  lobdossierMyStudents = 'student/all',
  lobdossierOverviewStudents = 'activity/index',
  carrierAssignment = 'carrier-assignment',
  carrierAssignmentAll = 'careerinterview/all',
  carrierAssignmentPreparation = 'assignment/overview/format/label/lob_career_interview_preparation',
  carrierAssignmentEvaluation = 'assignment/overview/format/label/lob_career_interview_review',
  handedInAssignments = 'handed-in-assignments',
  handedPerStudent = 'assigned/handed-in',
  handedPerAssignment = 'assignment/period/activated',
  activeAssignment = 'assigned/lobactivity/all',
  notAssigned = 'not_assigned',
  lobdossierReports = 'reports',
  reportActiveAssignments = 'report/active/assignments',
  lobVoorlichtingenManagement = 'info-management',
  lobVoorlichtingenSettings = 'settings',
  lobVoorlichtingenHbos = 'choicehbo',
  lobVoorlichtingenActivityType = 'activity_type',
  lobVoorlichtingenActivities = 'education/list/1',
  lobVoorlichtingenEducationDay = 'education_day',
  lobVoorlichtingenPhase = 'phase/index',
  lobVoorlichtingenOffer = 'offer',
  lobVoorlichtingenPhases = 'phase/settings/readonly',
  lobVoorlichtingenMyStudents = 'student/education_day?id=',
  lobVoorlichtingenEducation = 'student/education_day',
  lobVoorlichtingenBlock = 'block',
  lobVoorlichtingenPhasesSettings = 'phase/settings',
  lobVoorlichtingenClassroom = 'classroom',
  lobVoorlichtingenDigitalroom = 'digitalroom',
  lobVoorlichtingenSearch = 'student/education_day?id=42',
  lobVoorlichtingenRegistrations = 'education_report',
  lobVoorlichtingenSort = 'sort',
  ActivityAssignmentOverview = 'assignment/general/all',
  ActivityAssignmentOverview2 = 'assignment/overview/format/2',
  ActivityAssignmentOverview3 = 'assignment/overview/format/3',
  ChoiceSemester = 'choice-semester',
  ChoiceSemestersOverview = 'choice_semester',
  ChoiceSemestersAssignments = 'choicesemester/all',
  ChoiceSemesterArchivedAssignments = 'choicesemester/archived',
  InternshipAssignments = 'internship-assignments',
  InternshipAssignmentsOverview = 'internship-assignments',
  InternshipAssignmentsAssignments = 'internship-assignments/all',
  InternshipAssignmentsArchivedAssignments = 'internship-assignments/archived',
  monitorus = 'monitorus',
  monitorusReportsSubmenu = 'reports',
  monitorusList = '_monitorus/lists',
  monitorusImport = 'import',
  monitorusImportList = '_monitorus/index',
  monitorusTrainingTabel = '_monitorus/training',
  monitorusInfo = '_monitorus/info',
  monitorusStudentDetails = '_monitorus/students',
  monitorusData = '_monitorus/index',
  monitorusSettings = '_monitorus/settings',
  monitorusExplanation = '_monitorus/info',
  monitorusExports = '_monitorus/exports',
  monitorusCertificates = '_monitorus/cert',
  monitorusBenchmarking = '_monitorus/index',
  monitorusImportFiles = '_monitorus/uploadfile',
  monitorusReports = '_monitorus/benchmarking',

  lobActivities = 'lobactivities',
  offerReact = 'offer/react',
  activityOverview = 'activityoverview',
  activityOverviewStatus = 'activityoverview/status',
  activityOverviewStatusConcept = 'status/concept',
  activityOverviewStatusSubmitted = 'status/submitted',
  activityOverviewStatusDisapproved = 'status/disapproved',
  activityOverviewStatusApproved = 'status/approved',
  myStudentTab = 'student/tab',
  myStudentLeerlingenZonderInschrijving = 'leerlingen-zonder-inschrijving',
  myStudentLeerlingenMetInschrijving = 'leerlingen-met-inschrijving',
  myStudentLeerlingenMetOverinschrijving = 'leerlingen-met-overinschrijving',
  myStudentAlleLeerlingen = 'alle-leerlingen',
  registrations = 'registrations',
  student = 'student',
  registrationApplications = 'applications',
  registrationSignOuts = 'allsignouts',
  registrationOverApplications = 'overapplications',
  settingsDaysWindow = 'dayswindow',
  settingsPhases = 'phase',
  settingsRegion = 'region/setting',
  settingsMBO = 'choice/settings',
  settingMBO = 'choice/setting',
  settingsActivityType = 'activitytype',
  settingsOpenForRegions = 'region/choice/settings/openForRegions',
  settingsDateHandler = 'datehandler',
  settingsSurvey = 'google/survey',
  import

    = 'import',
  importActivity = 'activity/import',
  importActivityDate = 'activity/date/import',

  intergrip1Students = 'student',
  intergrip_students = 'students',
  UnfinishedRegistrations = 'pending',
  merge = 'student/merge',
  setupRiskStudents = '_vombo/setting',
  listStudent = 'list/student',
  removeChoices = '_vombo/removeVervolgkeuzesByChoice',

  intergrip1Monitoring = 'intergrip1-monitoring',
  email = 'maillog',
  sms = 'smslog',
  uploadedFiles = 'upload',

  intergrip1Manage = 'intergrip1-settings',
  moduleManagement = 'modules',

  mboCheckFetch = 'mbo-check-fetch',

  eld = 'eld',
  importLog = '_vombo/eldlog',
  int1connections = 'connections',
  proNexus = '_vombo/pronexus',
  fixes = 'fixes',
  choiceDuplicator = '_vombo/deduplicatevervolgkeuzes',
  choiceDeleter = '_vombo/removeVervolgkeuzesByChoice',
  int1export = '_vombo/export',
  int1Settings = '_vombo/setting',
  int1Students = '_vombo/index',
  studentsToDiscuss = 'discussion_round_student',
  progressVMbo = '_vombo/voortgangvmbo',
  progressMbo = '_vombo/voortgangmbo',
  checkMboSoftCheck = '_vombo/softcheckscontrole',
  warmTransferPerVMbo = '_vombo/warmeoverdracht/vmbo',
  warmTransferPerMbo = '_vombo/warmeoverdracht/mbo',
  bblWorkPlace = '_vombo/bbl',
  dashboard = '_vombo/adminDashboard',
  mboCheckYearOverview = '_vombo/mbocheckyearview',
  everAtLpRmc = '_vombo/ooitbijlp',
  admin = 'admin',
  discussionRound = 'discussion_round',
  bbl = '_vombo/bbl',
  warmTransfer = '_vombo/warmeoverdracht',
  potentialDropouts = '_vombo/risicoleerlingen',
  assignDiplomas = '_vombo/certification',
  printDiplomacard = '_vombo/certificatecard',
  sendChoiceForm = '_vombo/choiceform',
  acties = 'acties',
  outflowHistory = '_vombo/uitstroomhistorie',
  riskStatusesAtVo = '_vombo/risicostatussen',
  Dashboard = 'dashboard',
  DossierConnection = 'dossierlink',
  Flevoland = 'Flevoland',
  Groningen = 'Groningen',
  TaskList = 'task-list',
  TaskPeriod = 'task_period',
  TaskPart = 'task_part',
  Tasks = 'task',
  VoMboCheck = 'mbo-check',
  Instroom = 'inflow',
  VoSpecialTransfers = 'vo-special-transfer',
  MboSpecialTransfers = 'mbo-special-transfer',
  SoftMatch = 'soft-match',
  Mbo = 'mbo',
  Lp = 'lp',
  Active = 'active',
  Excluded = 'excluded',
  KeyFigures = 'key-figures',
  Budget = 'budget',
  Providers = 'providers',
  Year = 'year',
  Prognosis = 'prognosis',
  Referrers = 'referrers',
  Comparison = 'comparison',
  PoSchool = 'po-school',
  DaycareInstance = 'daycare-instance',
  PoInstitution = 'po-institution',
  Manage = 'manage',
  Trashed = 'trashed',
  KovPo = 'kov-po',
  Child = 'child',
  ObservationFormats = 'observation-formats',
  ChildYear = 'child_year',
  SchoolClassId = 'school_class_id',
  MboDelivery = 'mbo-delivery',
  CDelivery = 'c-delivery',
  General = 'general',
  ChildList = 'child-list',
  Observations = 'observations',
  Todo = 'todo',
  Children = 'children',
  Observation = 'observation',
  Blocks = 'blocks',
  Answers = 'answers',
  Questions = 'questions',
  QuestionResponses = 'questionResponses',
  Responseable = 'responseable',
  LinkedQuestion = 'linkedQuestion',
  NewChildren = 'new-children',
  TransferRequest = 'transfer-request',
  Status = 'status',
  SpecialTransfer = 'special-transfer',
  Caregivers = 'caregivers',
  MboLocations = 'mbo-locations',
  VoSettings = 'vo-settings',
  DashboardOCW = 'dashboard-ocw',
  MboDropouts = 'mbo-dropouts',
  BrinFile = 'brin-file',
  MboCheckApiLogs = 'mbo-check-api-logs',
  SocialMap = 'social-map',
}
